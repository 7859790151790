import AppHeader from "../../../basic/app-header/AppHeader";
import AppMenu from "../../../basic/app-menu/AppMenu";
import AppFooter from "../../../basic/app-footer/AppFooter";
import AppSetting from "../../../basic/app-setting/AppSetting";
import React, { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import { Modal } from "react-bootstrap";
import api from "../../../../services/axios";
import { postAxios } from "../../../../hooks/useAxios";
import { formatCpf } from "../../../../utils/formatCpf";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    FormGroup,
    Stack,
    TextField,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: "#6c757d",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));

function CreateSsjAN() {
    const [companies, setCompanies] = useState(null);
    const [names, setNames] = useState(null);
    const [types, setTypes] = useState(null);
    const [departments, setDepartments] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedSolicitant, setSelectedSolicitant] = useState("");
    const [fileList, setFileList] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [email, setEmail] = useState(null);
    const [department, setDepartment] = useState(null);
    const [nivel, setNivel] = useState(null);
    const [formData, setFormData] = useState({
        company: "",
        type: "",
        nature: "",
        priority: "",
        request: "",
        comment: "",
    });
    // Flag para garantir que os dados sao carregados do localStorage apenas uma vez
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // variaveis para limitacao dos campos de escrita a ate 500 caracteres
    const [comment, setComment] = useState("");
    const [request, setRequest] = useState("");
    const maxChars = 500;
    const [isAdmin, setIsAdmin] = useState(null);
    const [isAN, setIsAN] = useState(false);
    const [empresaAN, setEmpresaAN] = useState([]);
    const [solicitantesAN, setSolicitantesAN] = useState([]);
    const [advAN, setAdvAN] = useState([]);

    // estados para emails adicionais
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const handleShowSettingsModal = () => setShowSettingsModal(true);
    const handleCloseSettingsModal = () => setShowSettingsModal(false);
    const [emailInput, setEmailInput] = useState("");
    const [additionalEmails, setAdditionalEmails] = useState([]);
    const [selectedName, setSelectedName] = useState(null);

    // Carregar dados do localStorage quando o componente monta
    useEffect(() => {
        const savedFormData = localStorage.getItem("formData");
        if (savedFormData) {
            setFormData(JSON.parse(savedFormData));
        }
        setIsDataLoaded(true); // Set the flag to true after loading data
    }, []);

    // Salvar dados no localStorage sempre que formData for atualizado
    useEffect(() => {
        if (isDataLoaded) {
            // Only update localStorage if data has been loaded
            localStorage.setItem("formData", JSON.stringify(formData));
        }
    }, [formData, isDataLoaded]);

    // * Requisicoes

    // obter as opcoes dos selects
    useEffect(() => {
        const getFormOptions = async () => {
            postAxios("/api/get/form/options", null, null, {
                withCredentials: true,
            })
                .then((resp) => {
                    setCompanies(resp.data.response.bdClientes);
                    setNames(resp.data.response.bdSolicitantes);
                    setTypes(resp.data.response.bdTipos);
                    setDepartments(resp.data.response.departamentos);
                    setSelectedOption(resp.data.response.nome);
                    setEmail(resp.data.response.email);
                    setDepartment(resp.data.response.departamento);
                    setNivel(resp.data.response.nivel);
                    console.log(resp);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            window.location.href = "/login";
                        } else {
                            alert(
                                "Algo inesperado aconteceu, por favor entra em contato com o suporte!"
                            );
                        }
                    } else {
                        console.log(error.message);
                    }
                });
        };

        getFormOptions();
    }, []);
    console.log(names);

    useEffect(() => {
        const company = formData.company;
        const getFormOptionsAN = async () => {
            postAxios("/api/get/ssj-an", { company }, null, {
                withCredentials: true,
            })
                .then((resp) => {
                    console.log(resp);
                    setEmpresaAN(resp.data.clientes);
                    setAdvAN(resp.data.advogados);
                    setSolicitantesAN(resp.data.solicitantes);
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            window.location.href = "/login";
                        } else {
                            alert(
                                "Algo inesperado aconteceu, por favor entra em contato com o suporte!"
                            );
                        }
                    } else {
                        console.log(error.message);
                    }
                });
        };
        if (isAdmin && isAN) {
            getFormOptionsAN();
        }
    }, [isAdmin, isAN]);

    console.log(email);

    // obter o nivel do usuario
    useEffect(() => {
        const getUser = async () => {
            postAxios("/api/components/clients", null, null, {
                withCredentials: true,
            })
                .then((resp) => {
                    console.log(resp);
                    resp.data.user.nivel === "ADM"
                        ? setIsAdmin(true)
                        : setIsAdmin(false);
                    resp.data.user.functions &&
                        resp.data.user.functions.map((resp) => {
                            if (resp === "SSJ-ADM") {
                                setIsAN(true);
                            }
                        });
                })
                .catch((error) => {
                    window.location.href = "/login";
                    console.error("Erro na solicitação POST:", error);
                });
        };

        getUser();
    }, [isAdmin]);

    if (
        companies === null ||
        names === null ||
        types === null ||
        departments === null
    ) {
        return (
            <>
                <AppHeader />
                <AppMenu />
                <div className="content-wrapper">
                    <div className="card-body">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100vh",
                            }}
                        >
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <AppFooter />
                <AppSetting />
            </>
        );
    }

    const form = new FormData();
    fileList.forEach((file, index) => {
        form.append("files", file);
    });

    // envio do formulario
    function sendForm() {
        if (isProcessing) return; // Impede cliques adicionais enquanto já está em andamento

        setIsProcessing(true);
        const company = document.querySelector("#company").value;
        const type = document.querySelector("#type").value;
        const nature = document.querySelector("#nature").value;
        const nameSolicitant =
            document.querySelector("#nameSolicitantAN").value;
        const email = document.querySelector("#emailAN").value;
        const department = document.querySelector("#department").value;
        const priority = document.querySelector("#priority").value;
        const request = document.querySelector("#request").value.toUpperCase();
        const comment = document.querySelector("#comment").value.toUpperCase();
        const advogado = document.querySelector("#adv").value;

	

        // insercao no formulario para o envio
        form.append("company", company);
        form.append("type", type);
        form.append("nature", nature);
        form.append("nameSolicitant", nameSolicitant);
        form.append("email", email);
        form.append("department", department);
        form.append("priority", priority);
        form.append("request", request);
        form.append("comment", comment);
        form.append("advogado", advogado);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data", // Importante: defina o cabeçalho 'Content-Type' como 'multipart/form-data' para enviar arquivos
            },
            withCredentials: true,
        };

        // Log dos dados do FormData
        for (let [key, value] of form.entries()) {
            console.log(`${key}: ${value}`);
        }

        postAxios("/api/create/ssj-an", form, null, config)
            .then((resp) => {
                localStorage.removeItem("formData");
                window.location = "/ssj/status";
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        window.location.href = "/login";
                    } else if (error.response.data !== "") {
                        alert(error.response.data);
                    } else {
                        alert("Ocorreu um erro no servidor!");
                    }
                } else {
                    alert(error.message);
                }
            })
            .finally(() => {
                setIsProcessing(false); // Habilita o botão novamente após a conclusão ou erro
            });
    }

    // * Funcoes auxiliares

    // insercao dos arquivos
    const handleFileChange = (files) => {
        const fileListArray = Array.from(files);
        // atualizar o estado mantendo o que ja havia nele e adicionando os novos arquivos
        setFileList((prevFileList) => [...prevFileList, ...fileListArray]);
    };

    // inserir arquivos arrastando
    const handleFileDrop = (e) => {
        e.preventDefault();
        const selectedFile = e.dataTransfer.files;
        handleFileChange(selectedFile);
    };

    // deletar arquivo
    const handleRemoveFile = (index) => {
        const updatedFileList = [...fileList];
        updatedFileList.splice(index, 1);
        setFileList(updatedFileList);
    };

    // preview do nome do arquivo adicionado
    const handlePreviewClick = () => {
        // Ative o input de arquivo quando o usuário clica no espaço reservado
        const fileInput = document.getElementById("fileInput");
        if (fileInput) {
            fileInput.click();
        }
    };

    // funcoes para limitacao dos campos a 500 caracteres
    const handleCommentChange = (e) => {
        const inputText = e.target.value;
        if (inputText.length <= maxChars) {
            setComment(inputText);
        }
    };

    const handleRequestChange = (e) => {
        const inputText = e.target.value;
        if (inputText.length <= maxChars) {
            setRequest(inputText);
        }
    };

    const handleChangeForm = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCancel = () => {
        localStorage.removeItem("formData");
        window.location = `/`;
    };

    const handleNameChange = (event) => {
        const nomeSelecionado = event.target.value;
        setSelectedName(nomeSelecionado);
    };

    function loadForm(event) {
        const { name, value } = event.target;
        setSelectedSolicitant((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    //Funcionalidade emails adicionais (Configuracao do ssj)
    // Modal para configurações
    const renderSettingsModal = () => (
        <Dialog
            open={showSettingsModal}
            onClose={handleCloseSettingsModal}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Configurações do SSJ</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {isAdmin ? (
                        <div style={{ marginTop: 15 }}>
                            <Typography variant="subtitle1">
                                Adicionar emails:
                            </Typography>
                            <TextField
                                label="Adicionar email"
                                variant="outlined"
                                size="small"
                                fullWidth
                                value={emailInput}
                                onChange={(e) => setEmailInput(e.target.value)}
                                onKeyPress={handleEmailKeyPress}
                                style={{ marginTop: 4 }}
                            />
                            <Typography
                                variant="subtitle1"
                                style={{ marginTop: 10 }}
                            >
                                Emails adicionais:
                            </Typography>
                            <List>
                                {additionalEmails.map((email, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={email} />
                                        <IconButton
                                            edge="end"
                                            onClick={() =>
                                                handleRemoveEmail(index)
                                            }
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    ) : (
                        <></>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <DialogActions>
                    {isAdmin ? (
                        <Button onClick={handleSaveEmails} color="primary">
                            Salvar Configurações
                        </Button>
                    ) : (
                        <></>
                    )}
                    <Button
                        onClick={handleCloseSettingsModal}
                        style={{ color: "#ff1744" }}
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </DialogActions>
        </Dialog>
    );

    const handleEmailKeyPress = (e) => {
        if (e.key === "Enter" && emailInput) {
            setAdditionalEmails([...additionalEmails, emailInput]);
            setEmailInput("");
        }
    };

    const handleRemoveEmail = (index) => {
        setAdditionalEmails(additionalEmails.filter((_, i) => i !== index));
    };

    const handleSaveEmails = async () => {
        console.log(additionalEmails);
        try {
            const response = await api.post(
                "/api/additional-emails",
                { emails: additionalEmails },
                { withCredentials: true }
            );

            if (response.status === 200) {
                alert("Configurações salvas com sucesso!");
                handleCloseSettingsModal();
            } else {
                alert("Houve um problema ao salvar as configurações.");
            }
        } catch (error) {
            console.error("Erro ao salvar emails adicionais", error);
            alert("Erro ao salvar configurações.");
        }
    };

    return (
        <>
            <AppHeader />
            <AppMenu />
            <div class="content-wrapper">
                <section class="content-header" style={{ marginBottom: -5 }}>
                    <div class="container-fluid">
                        <div className="row mb-2">
                            <div class="col-sm-6">
                                <h1>
                                    Solicitação de Serviço Jurídico AN (SSJ)
                                </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <a href="/">Início</a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        SSJ-AN
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="content">
                    <div class="container-fluid">
                        <div class="card card-default">
                            <div class="card-header bg-gray center-element">
                                <h3
                                    class="card-title"
                                    style={{ fontWeight: 600 }}
                                >
                                    Formulário
                                </h3>
                            </div>
                            <div class="card-body">
                                <div
                                    className="row"
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <div className="form-group">
                                        <IconButton
                                            onClick={handleShowSettingsModal}
                                        >
                                            <SettingsIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-3">
                                        <label class="required" for="company">
                                            Empresa:
                                        </label>
                                        <select
                                            type="text"
                                            name="company"
                                            id="company"
                                            class="form-control"
                                            value={formData.company}
                                            onChange={handleChangeForm}
                                        >
                                            <option></option>
                                            {empresaAN &&
                                                empresaAN.map((resp) => (
                                                    <option>
                                                        {resp.cliente}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-3">
                                        <label
                                            className="required"
                                            for="nameSolicitant"
                                        >
                                            Nome do solicitante:
                                        </label>
                                        <select
                                            name="nameSolicitantAN"
                                            id="nameSolicitantAN"
                                            className="form-control"
                                            value={formData.nameSolicitantAN}
                                            onChange={(event) => {
                                                handleNameChange(event);
                                                handleChangeForm(event);
                                            }}
                                        >
                                            <option></option>
                                            {solicitantesAN &&
                                                solicitantesAN.map((resp) => (
                                                    <option>{resp.nome}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-3">
                                        <label class="required" for="email">
                                            Email do solicitante:
                                        </label>
                                        <input
                                            type="email"
                                            name="emailAN"
                                            id="emailAN"
                                            class="form-control"
                                            value={formData.emailAN}
                                            onChange={(event) => {
                                                loadForm(event);
                                                handleChangeForm(event);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-3">
                                        <label class="required" for="adv">
                                            Advogado(a) responsável:
                                        </label>
                                        <select
                                            type="select"
                                            name="adv"
                                            id="adv"
                                            class="form-control"
                                            value={formData.adv}
                                            onChange={handleChangeForm}
                                        >
                                            <option></option>
                                            {advAN &&
                                                advAN.map((resp) => (
                                                    <option>
                                                        {resp.participante}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-3">
                                        <label class="required" for="type">
                                            Tipo:
                                        </label>
                                        <select
                                            type="select"
                                            name="type"
                                            id="type"
                                            class="form-control"
                                            value={formData.type}
                                            onChange={handleChangeForm}
                                        >
                                            <option></option>
                                            {types &&
                                                types.map((resp) => (
                                                    <option>{resp.tipo}</option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-3">
                                        <label class="required" for="nature">
                                            Natureza:
                                        </label>
                                        <select
                                            type="select"
                                            name="nature"
                                            id="nature"
                                            class="form-control"
                                            value={formData.nature}
                                            onChange={handleChangeForm}
                                        >
                                            <option></option>
                                            <option>
                                                EMPRESARIAL (Quaisquer outros
                                                serviços que não seja natureza
                                                Trabalhista)
                                            </option>
                                            <option>
                                                TRABALHISTA (Serviços que
                                                envolvam contrato de trabalho,
                                                direitos ou deveres dos
                                                funcionários)
                                            </option>
                                        </select>
                                    </div>
                                    <div className="form-group col-3">
                                        <label
                                            class="required"
                                            for="department"
                                        >
                                            Departamento:
                                        </label>
                                        <select
                                            name="department"
                                            id="department"
                                            className="form-control"
                                            value={formData.department}
                                            onChange={handleChangeForm}
                                        >
                                            <option></option>
                                            {departments &&
                                                departments.map((resp) => (
                                                    <option key={resp.id}>
                                                        {resp.departamento}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-3">
                                        <label class="required" for="priority">
                                            Prioridade:
                                        </label>
                                        <select
                                            name="priority"
                                            id="priority"
                                            class="form-control"
                                            value={formData.priority}
                                            onChange={handleChangeForm}
                                        >
                                            <option></option>
                                            <option>
                                                URGENTE - entrega em até 01 dia
                                            </option>
                                            <option>
                                                MÉDIA - entrega em até 03 dias
                                            </option>
                                            <option>
                                                NORMAL - entrega em até 05 dias
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-4">
                                        <i class="fa fa-paperclip"></i>
                                        <label
                                            style={{ marginLeft: 3 }}
                                            className="required"
                                        >
                                            Pedido do Cliente:
                                        </label>
                                        <textarea
                                            id="request"
                                            class="form-control"
                                            name="request"
                                            rows="6"
                                            placeholder="Coloque aqui o detalhamento do serviço pedido..."
                                            value={request || formData.request}
                                            onChange={(event) => {
                                                handleRequestChange(event);
                                                handleChangeForm(event);
                                            }}
                                        ></textarea>
                                        <p className="caracter-message">
                                            Caracteres restantes:{" "}
                                            {maxChars - request.length}
                                        </p>
                                    </div>
                                    <div className="form-group col-4">
                                        <i class="fa fa-comment"></i>
                                        <label style={{ marginLeft: 3 }}>
                                            Comentários:
                                        </label>
                                        <textarea
                                            id="comment"
                                            class="form-control"
                                            name="comment"
                                            rows="6"
                                            placeholder="Coloque aqui comentários adicionais..."
                                            value={comment || formData.comment}
                                            onChange={(event) => {
                                                handleCommentChange(event);
                                                handleChangeForm(event);
                                            }}
                                        ></textarea>
                                        <p className="caracter-message">
                                            Caracteres restantes:{" "}
                                            {maxChars - comment.length}
                                        </p>
                                    </div>
                                    <div className="form-group col-4">
                                        <i class="fa fa-folder"></i>
                                        <label
                                            htmlFor="profileImage"
                                            style={{ marginLeft: 3 }}
                                        >
                                            Anexar documento:
                                        </label>
                                        <div
                                            className={`form-group file-input-preview ${
                                                fileList.length > 0
                                                    ? "file"
                                                    : "image"
                                            }`}
                                            onDrop={handleFileDrop}
                                            onDragOver={(e) =>
                                                e.preventDefault()
                                            }
                                        >
                                            <div
                                                className="square-placeholder"
                                                onClick={handlePreviewClick}
                                            >
                                                <div className="file-container">
                                                    {fileList.length > 0 ? (
                                                        <ul className="archive-list">
                                                            {fileList.map(
                                                                (
                                                                    file,
                                                                    index
                                                                ) => (
                                                                    <li className="archive-item file-item">
                                                                        {
                                                                            file.name
                                                                        }
                                                                        <button
                                                                            className=" remove-archive"
                                                                            onClick={() =>
                                                                                handleRemoveFile(
                                                                                    index
                                                                                )
                                                                            }
                                                                        >
                                                                            <ClearIcon />
                                                                        </button>
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        <div className="archive-indicator">
                                                            <CloudUploadIcon />
                                                        </div>
                                                    )}
                                                </div>
                                                <input
                                                    id="fileInput"
                                                    type="file"
                                                    multiple
                                                    onChange={(e) =>
                                                        handleFileChange(
                                                            e.target.files
                                                        )
                                                    }
                                                    style={{ display: "none" }}
                                                />
                                            </div>
                                            <label
                                                htmlFor="fileInput"
                                                className="small-text-archive"
                                            >
                                                Clique ou arraste!
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 center-element">
                                    <button
                                        type="button"
                                        class="cancel btn btn-gray"
                                        onClick={handleCancel}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        class="enviar btn btn-gray"
                                        style={{ marginLeft: 5 }}
                                        onClick={sendForm}
                                        disabled={isProcessing}
                                    >
                                        {isProcessing
                                            ? "Aguarde..."
                                            : "Confirmar"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {renderSettingsModal()}
            </div>
            <AppFooter />
            <AppSetting />
        </>
    );
}

export default CreateSsjAN;
